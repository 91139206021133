<template>
  <div>
    <SimpleTable ref="table" :tableProps="tableProps" :queryFun="queryFun">
      <template slot="left-r" slot-scope="{ data }">
        <el-button
          v-if="hasPerms('M12SM07_add')"
          @click="addOrEdit(null, 1)"
          class="add-btn"
          icon="el-icon-circle-plus-outline"
          >添加</el-button
        >
      </template>
      <template slot="equipmentNature" slot-scope="{ data }">
        <span v-if="data.row.equipmentNature">{{
          getDictLabel(data.row.equipmentNature, DeviceNatureDict)
        }}</span>
        <span v-else>{{
          getDictLabel(data.row.equipmentNature, DeviceNatureDict)
        }}</span>
      </template>
      <template slot="isEnable" slot-scope="{ data }">
        <div
          v-if="data.row.isEnable"
          style="display: flex; align-items: center; color: #00bc0d"
        >
          <div
            style="
              width: 12px;
              height: 12px;
              background-color: #00bc0d;
              border-radius: 50%;
            "
          ></div>
          <span style="margin-left: 4px">启用</span>
        </div>
        <div v-else style="display: flex; align-items: center; color: #999999">
          <div
            style="
              width: 12px;
              height: 12px;
              background-color: #999999;
              border-radius: 50%;
            "
          ></div>
          <span style="margin-left: 4px">禁用</span>
        </div>
      </template>
      <template slot="terminalType" slot-scope="{ data }">
        {{ changTerminalTypeToName(data.row.terminalType) }}
      </template>
      <div slot="action" slot-scope="{ data }">
        <el-button
          v-if="hasPerms('M12SM07_edit')"
          @click="addOrEdit(data.row, 2)"
          type="text"
          >编辑</el-button
        >
        <el-button
          v-if="hasPerms('M12SM07_del')"
          @click="deleteProVer({ id: data.row.id })"
          type="text"
          style="color: #f64a2d"
          >删除</el-button
        >
      </div>
    </SimpleTable>
    <ProductVersionAddOrEditDialog
      :show.sync="show"
      :detailData="detailData"
      @submit="
        () => {
          $refs.table.getData(true);
        }
      "
    ></ProductVersionAddOrEditDialog>
  </div>
</template>

<script>
import SimpleTable from "@/components/SimpleTable";
import ProductVersionAddOrEditDialog from "./components/ProductVersionAddOrEditDialog";
import {
  getProductVersionList,
  deleteProductVersion,
} from "@/api/vehicleEquipment";
import { DeviceNatureDict, ProductTerminal } from "@/js/const/vehicle.js";
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
  components: { SimpleTable, ProductVersionAddOrEditDialog },
  data() {
    return {
      hasPerms: hasPerms,
      show: false,
      detailData: {},
      DeviceNatureDict: DeviceNatureDict,
      tableProps: {
        fuzzyQueryKey: "fuzzySearch",
        isFuzzyQuery: false,
        currentPageKey: "index",
        currentSizeKey: "size",
        totalKey: "total",
        height: 600,
        searchConfigs: [
          {
            label: "版本名称",
            type: "input",
            modelKey: "versionName",
          },
          {
            label: "设备性质",
            type: "select",
            modelKey: "equipmentNature",
            option: DeviceNatureDict.map((item) => {
              return {
                dictValue: item.label,
                dictKey: item.value,
              };
            }),
          },
          {
            label: "创建人",
            type: "input",
            modelKey: "createUserName",
          },
          {
            label: "创建时间",
            type: "daterange",
            daterangeKeys: ["createTimeBegin", "createTimeEnd"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "createT",
          },
        ],
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "versionName",
            label: "版本名称",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "equipmentNature",
            label: "设备性质",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },
          {
            prop: "terminalType",
            label: "产品终端",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },
          {
            prop: "isEnable",
            label: "启用状态",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },
          {
            prop: "createUserName",
            label: "创建人",
            width: "",
            isShow: true,
          },
          {
            prop: "createTime",
            label: "创建时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "action",
            label: "操作",
            width: "100",
            type: "",
            slot: true,
            isShow: true,
          },
        ],
      },
    };
  },
  methods: {
    async queryFun(queryParams) {
      let res = await getProductVersionList(queryParams);
      return {
        localData: res?.obj?.list || [],
        total: res.obj.total || 0,
      };
    },
    addOrEdit(row, type) {
      // 1 新增 2 编辑
      if (type == 2) {
        this.detailData = row;
      } else {
        this.detailData = {};
      }
      this.show = true;
    },
    deleteProVer(data) {
      this.$confirm("此操作将删除该产品版本, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteProductVersion(data).then((res) => {
            if (res.flag) {
              this.$refs.table.getData(true);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getDictLabel(value, dict) {
      return dict.find((item) => item.value == value)?.label || "";
    },
    changTerminalTypeToName(string) {
      let names = [];
      let types = string.split(",");
      types.forEach((item) => {
        names.push(this.getDictLabel(item, ProductTerminal) || item);
      });
      let res = names.join(",");
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
  background: #00bc0d;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #00bc0d;
}
</style>
